import React from "react"
import { AppFunctionComponent } from "../../../types"
import styled from "styled-components"
import Section, {
  SectionArticle,
  SectionHeading,
  SectionLayout,
} from "../../../components/common/sections/section.component"
import { PrimaryHeading } from "../../../components/common/heading.component"
import NotFoundPageIllustration from "../../../images/sections/image1.png"

const NotFoundSection = styled(Section)`
  --text-max-width: 560px;
  align-items: center;

  ${SectionHeading} {
    margin-bottom: 30px;
    justify-self: center;
    img {
      margin-top: 30px;
      width: 100%;
    }

    ${PrimaryHeading} {
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: ${({ theme }) =>
          theme.breakpoint.tabletHorizontal}px) {
        margin-top: 50px;
      }
    }
  }

  svg {
    width: 100%;
    height: auto;
  }

  ${SectionArticle} {
    text-align: left;
    max-width: 100%;
  }
`

const EUPageContent: AppFunctionComponent = () => {
  return (
    <NotFoundSection layout={SectionLayout.TextLeftAligned}>
      <SectionHeading>
        <img src={NotFoundPageIllustration} alt="European union clause" />
      </SectionHeading>
      <SectionArticle>
        <p>
          Firma Mudita Spółka z ograniczoną odpowiedzialnością uzyskała dotację
          z Unii Europejskiej na projekt „Półautomatyczne narzędzie wspierające
          tworzenie aplikacji na urządzenia z ekranami typu e-ink z bezpiecznym
          systemem dystrybucji tych aplikacji”.
        </p>
        <p>
          Przedmiotem projektu są prace badawczo-rozwojowe w zakresie
          informatyki zmierzające do opracowania:
          <br />
          1. innowacyjnego, zaawansowanego pakietu gotowych komponentów,
          narzędzi i bibliotek, który będzie dostosowany do urządzeń mobilnych z
          ekranami e-ink (EPD – Electronic Paper Display) oraz
          <br />
          2. zintegrowanej z ww. systemem, platformy dystrybucyjnej na system
          Android.
        </p>
        <p>
          Przeznaczeniem projektowanego rozwiązania będzie konwersja
          istniejących aplikacji mobilnych z urządzeń z ekranami LCD/OLED na
          urządzenia z ekranem EPD oraz tworzenie nowych aplikacji. Wyzwanie
          technologiczne wynika wprost z specyfikacji technicznej ekranów e-ink,
          ich producenci nie są w stanie zniwelować istniejących wad (w tym
          niskiej szybkości odświeżania) poprzez samo ulepszenie urządzeń.
        </p>
        <p>
          Prace podzielono na: Etap I – badania związane z opracowaniem nowych
          interakcji z aplikacjami za pomocą badań z obszaru HCI (human-computer
          interaction). Głównym problemem badawczym jest stworzenie alternatywy
          popularnych na ekranach LCD/OLED interakcji, m.in. takich jak
          „scrolling”, „pinch zoom”, „drag&drop” czy „swipe”. Testy obejmą m.in.
          rejestrację biofeedbacku w celu zminimalizowania obciążenia
          kognitywnego użytkownika. Etap II – prace rozwojowe dotyczące MMD,
          które skupią się głównie na opracowaniu najbardziej efektywnej metody
          konwersji pomiędzy ekosystemami LCD/OLED i e-ink. Zestaw narzędzi
          powinien zapewniać kompatybilność z możliwie najszerszym spektrum
          urządzeń z ekranami EPD. Etap III – prace rozwojowe dotyczące
          bezpieczeństwa aplikacji i ich dystrybucji na urządzenia końcowe,
          przeznaczone dla użytkowników i deweloperów. Szczególnie ważnym będzie
          zapewnienie najwyższego stopnia bezpieczeństwa użytkownika m.in. przed
          kradzieżą danych.
        </p>
        <p>
          Rezultatem projektu będą kompatybilne ze sobą: pakiet komponentów i
          narzędzi wspierających tworzenie i konwersję aplikacji mobilnych pn.:
          Mudita Mindful Design (MMD) oraz platforma dystrybucyjna z
          zaimplementowanymi mechanizmami bezpieczeństwa. Rozwiązanie kierowane
          jest do deweloperów i konsumentów, którzy korzystać będą z aplikacji i
          urządzeń e-ink.
        </p>
        <p>#FunduszeUE #FunduszeEuropejskie</p>
        <p>
          Wartość projektu : 10.809.512,84 zł
          <br />
          Wysokość wkładu z Funduszy Europejskich: 6.199.164,18 zł
        </p>
      </SectionArticle>
    </NotFoundSection>
  )
}

export default EUPageContent
